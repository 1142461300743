import React from "react";
import { useSelector } from "react-redux";
import { useCreateRepoMutation } from "source/api/repos/useQueryRepos";
import { DEFAULT_REPO_NAME, SILVER_LAKE_ORG_ID } from "source/constants";
import { usePublicRepo } from "source/hooks/permissions/useCanEditRepo";
import { useCurrentDoc } from "source/hooks/doc-viewer/useCurrentDoc";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useOrgRole } from "source/hooks/permissions/useOrgRole";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getCurrentOrg } from "source/redux/organization";
import { getCurrentPage } from "source/utils/helpers";
import { getDisableLocalUpload } from "source/utils/integrations/status";
import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { BottomAddButton } from "source/components/sidebar/components/BottomAddButton";

export const NewFolderDocButton = () => {
  const { router } = useGetRouter();
  const goToRepoAddDocs = useGlobalNavigator().goToRepoAddDocs;
  const createRepoMutation = useCreateRepoMutation();

  const currentRepo = useCurrentRepo(true);
  const currentDoc = useCurrentDoc();
  const isPublicRepo = usePublicRepo(currentRepo);

  const org = useSelector(getCurrentOrg);
  const orgRole = useOrgRole();
  const disablePersonalUploads = getDisableLocalUpload(org);
  const page = getCurrentPage(router.pathname);

  const showButton =
    ["ChatDocs", "Search"].includes(page) && !disablePersonalUploads;

  if (!showButton) return null;

  const handleNewDocClick = () => {
    if (currentRepo) {
      goToRepoAddDocs(currentRepo.id, currentDoc?.id);
    }
  };

  const handleNewFolderClick = () => {
    createRepoMutation.mutate({
      name: DEFAULT_REPO_NAME,
      is_private: true,
      organization_id: undefined,
    });
  };

  const shouldShowNewDocButton =
    router.pathname.includes("/repos/") &&
    !isPublicRepo &&
    !router.pathname.includes("/add") &&
    !currentRepo?.integrations?.findox &&
    currentRepo?.role !== "viewer";

  const shouldShowNewFolderButton =
    org?.id !== SILVER_LAKE_ORG_ID || orgRole === "admin";

  return (
    <div className="my-4">
      {shouldShowNewDocButton && (
        <BottomAddButton
          text="New Doc"
          icon={<PostAddSharpIcon style={{ fontSize: "1.25rem" }} />}
          onClick={handleNewDocClick}
        />
      )}
      {shouldShowNewFolderButton && (
        <BottomAddButton
          text="New Folder"
          icon={<CreateNewFolderOutlinedIcon style={{ fontSize: "1.25rem" }} />}
          onClick={handleNewFolderClick}
        />
      )}
    </div>
  );
};
