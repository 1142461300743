import { offset } from "@floating-ui/react";
import React from "react";
import { Tooltip, TooltipProps } from "source/components/library/Tooltip";

type Props = Pick<TooltipProps, "children"> & {
  title: string;
};

export const TabBarTooltip = ({ title, children }: Props) => {
  return (
    <Tooltip
      content={<span className="text-xs leading-4 text-white">{title}</span>}
      floatingOptions={{ placement: "right", middleware: [offset(10)] }}
      styles={{
        Tooltip:
          "flex h-6 items-center justify-center rounded-[3px] border-none !bg-neutral-800 !px-2",
        Wrapper: "w-full justify-center flex",
      }}
    >
      {children}
    </Tooltip>
  );
};
