import React from "react";
import { Tabs } from "./tabs/Tabs";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { OrgDropdown } from "./OrgDropdown";
import { UserDropdown } from "./UserDropdown";
import { API_ENV, LOCAL_ENV, STAGING_ENV } from "source/envConstants";
import { Badge } from "packages/core";
import { HebbiaLogo } from "../library/Logo/HebbiaLogo";
import Link from "next/link";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { NewFolderDocButton } from "./NewFolderDocButton";

export const TabBar = () => {
  const showDevEnvironment = useFeatureFlag("showDevEnvironment");
  const { homepage } = useGlobalNavigator();

  return (
    <div className="sticky left-0 top-0 z-30 flex h-screen w-[52px] flex-none flex-col items-center justify-between overflow-y-auto overscroll-none border-r border-neutral-200 bg-neutral-50 py-4">
      <div className="flex w-full flex-col items-center gap-4">
        <OrgDropdown />
        <Tabs />
      </div>
      <div className="flex flex-col items-center gap-2">
        <NewFolderDocButton />

        {showDevEnvironment && [LOCAL_ENV, STAGING_ENV].includes(API_ENV) && (
          <Badge className="text-[7px]">{API_ENV.toUpperCase()}</Badge>
        )}

        <UserDropdown />
        <Link
          href={homepage}
          className="group flex w-full cursor-pointer justify-center py-2"
        >
          <HebbiaLogo
            width={20}
            height={20}
            className="text-neutral-300 transition-colors hover:text-neutral-400"
          />
        </Link>
      </div>
    </div>
  );
};
