import React from "react";
import { getCurrentOrg, getOrgs } from "source/redux/organization";
import Image from "next/image";
import { useSelector } from "react-redux";
import {
  Dropdown,
  IconButton,
  MaterialSymbolsIcon,
  Menu,
  MenuItem,
} from "core";
import { useState } from "react";
import { substringMatchOrgs } from "source/components/gigabar/components/FileSection/components/GigabarOrgSwitcher";
import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import { useOrgSwitch } from "source/hooks/useOrgSwitch";
import { flip, offset } from "@floating-ui/react";
import { TabBarTooltip } from "./tabs/TabBarTooltip";

const ORGS_TO_SHOW_SEARCH_BAR = 5;

export const OrgDropdown = () => {
  const orgs = useSelector(getOrgs);
  const currentOrg = useSelector(getCurrentOrg);

  const canSwitchOrg = orgs.length > 1;
  const showSearchBar = orgs.length > ORGS_TO_SHOW_SEARCH_BAR;

  const { onOrgSwitch } = useOrgSwitch();

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  // if (orgs.length < 2) return null;

  const filteredOrgs = searchValue
    ? substringMatchOrgs(searchValue, orgs)
    : orgs;

  return (
    <Dropdown
      variant="text"
      size="sm"
      as={IconButton}
      floatingOptions={{
        placement: "right-end",
        middleware: [
          offset(5), // Adjust the vertical spacing between the button and dropdown
          flip(), // Ensure the dropdown flips to a suitable position if there's not enough space
        ],
      }}
      className="m-1.5"
      disabled={!canSwitchOrg}
      renderMenu={() => (
        <Menu className="p-0">
          {showSearchBar && (
            <input
              type="text"
              value={searchValue}
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full border-b border-gray-200 px-4 py-2 text-sm leading-5 text-neutral-800 outline-none placeholder:text-gray-400"
            />
          )}
          <div className="max-h-[220px] overflow-y-scroll">
            {filteredOrgs.map((org) => (
              <MenuItem
                left={
                  org.logo_url ? (
                    <div className="relative inline-block h-5 w-5">
                      <Image
                        src={org.logo_url}
                        alt={`${org.name}`}
                        objectFit="contain"
                        fill={true}
                      />
                    </div>
                  ) : (
                    <div className="relative inline-block h-5 w-5">
                      <CorporateFareSharpIcon fontSize="small" />
                    </div>
                  )
                }
                onClick={() => onOrgSwitch(org)}
              >
                <div className="flex w-full flex-row items-center justify-between">
                  {org.name}
                  {org.id === currentOrg?.id && (
                    <MaterialSymbolsIcon
                      icon="check"
                      size="base"
                      className="self-center text-gray-500"
                    />
                  )}
                </div>
              </MenuItem>
            ))}
          </div>
        </Menu>
      )}
    >
      <TabBarTooltip title={currentOrg?.name ?? "Organization"}>
        <div className="flex h-6 w-6 items-center justify-center overflow-hidden rounded-[4px] border border-neutral-300 bg-white">
          {currentOrg?.logo_url ? (
            <Image
              src={currentOrg.logo_url}
              alt={`${currentOrg.name}`}
              width={16}
              height={16}
            />
          ) : (
            <CorporateFareSharpIcon fontSize="small" />
          )}
        </div>
      </TabBarTooltip>
    </Dropdown>
  );
};
