import React from "react";
import { TabBarTooltip } from "source/components/tab-bar/tabs/TabBarTooltip";

type Props = {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export const BottomAddButton = ({ text, icon, onClick }: Props) => {
  return (
    <TabBarTooltip title={text}>
      <div
        className="group flex w-full cursor-pointer flex-col items-center gap-1 p-2"
        onClick={onClick}
      >
        <div className="flex min-h-5 w-full items-center justify-center rounded-lg text-neutral-400 transition-all hover:text-neutral-500">
          {icon}
        </div>
      </div>
    </TabBarTooltip>
  );
};
