import React, { useState } from "react";
import {
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useFloating,
  offset,
  autoUpdate,
  FloatingPortal,
  useTransitionStyles,
  UseFloatingOptions,
} from "@floating-ui/react";
import classNames from "classnames";

export type TooltipProps = {
  title?: string;
  description?: string;
  content?: React.ReactNode;
  children: React.ReactNode;
  floatingOptions?: Partial<UseFloatingOptions>;
  styles?: {
    Tooltip?: string;
    Wrapper?: string;
  };
};

export const Tooltip = ({
  title,
  description,
  children,
  content,
  floatingOptions,
  styles,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
    ...floatingOptions,
  });

  const hover = useHover(context, {
    move: false,
    delay: { open: 400, close: 0 },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: "tooltip",
  });
  const { styles: transitionStyles } = useTransitionStyles(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        className={styles?.Wrapper}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...transitionStyles }}
            className={classNames(
              "z-50 flex max-w-[258px] flex-col gap-1 rounded-md border border-gray-200 bg-white px-4 py-3 shadow-md",
              styles?.Tooltip
            )}
            {...getFloatingProps()}
          >
            {content}
            {title && (
              <span className="text-xs font-medium leading-4 text-neutral-800">
                {title}
              </span>
            )}
            {description && (
              <span className="text-xs font-normal leading-4 text-gray-500">
                {description}
              </span>
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
