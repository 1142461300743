import React from "react";
import { classNames, IconButton } from "core";
import { TabBarTooltip } from "./TabBarTooltip";
import { useGetRouter } from "source/hooks/useGetRouter";

export type TabProps = {
  icon: React.ReactNode;
  page: string;
  isSelected?: boolean;
  location: string;
  onClick: (nextLocation: string) => void;
};

export const Tab = ({
  icon,
  page,
  location,
  isSelected,
  onClick,
}: TabProps) => {
  const { router } = useGetRouter();
  return (
    <TabBarTooltip title={page}>
      <IconButton
        onClick={() => {
          router.push(location);
          onClick(location);
        }}
        variant="text"
        size="lg"
      >
        <div
          className={classNames(
            "flex min-h-5 w-full items-center justify-center rounded-lg p-2 transition-all",
            isSelected
              ? "text-brand-500 hover:text-brand-600 [&_svg]:text-brand-500"
              : "text-neutral-400 hover:text-neutral-500 [&_svg]:text-neutral-400"
          )}
        >
          {icon}
        </div>
      </IconButton>
    </TabBarTooltip>
  );
};
