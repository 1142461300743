import { useUser } from "@auth0/nextjs-auth0/client";
import DefaultProfilePic from "assets/icons/profilePicDefault.png";
import { MenuRow } from "source/components/shared/MenuRow";
import Image from "next/image";
import React, { useState } from "react";
import { clearAuthToken } from "source/auth/localStorage";
import {
  useFloating,
  offset,
  flip,
  FloatingPortal,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import { useSearchStateManager } from "source/hooks/useSetRouter";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";
import { DropdownProfile } from "./DropdownProfile";
import { TabBarTooltip } from "./tabs/TabBarTooltip";

type Props = {
  isOnboarded?: boolean;
};

// FOR AUTH ROUTES, DO NOT USE router.push()
export const UserDropdown = ({ isOnboarded = true }: Props) => {
  const { user, isLoading: isLoadingUser } = useUser();
  const { clearUserTargetState } = useSearchStateManager();
  const settingsModal = useModal(ModalTypes.SettingsModal);

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "right-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(15), flip()],
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const handleOpenSettings = () => {
    settingsModal.open({});
  };

  const handleLogout = () => {
    goToLogout();
    clearAuthToken();
  };

  const goToLogout = () => {
    clearUserTargetState();
    window.location.href = "/api/auth/logout";
  };

  const goToLoginReturnToSearch = () => {
    clearUserTargetState();
    window.location.href = "/api/auth/login";
  };

  if (isLoadingUser || !user) {
    return (
      <span
        className="cursor-pointer px-1 pr-4 text-secondary hover:bg-shadow/30"
        onClick={() => goToLoginReturnToSearch()}
      >
        Login
      </span>
    );
  }

  return (
    <div>
      <TabBarTooltip title={user.name ?? ""} {...getReferenceProps()}>
        <button
          ref={refs.setReference}
          className="cursor-pointer"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Image
            alt="Profile Photo"
            width={24}
            height={24}
            src={user?.picture ? (user.picture as string) : DefaultProfilePic}
            className="overflow-hidden rounded-full"
          />
        </button>
      </TabBarTooltip>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-50 w-[260px] rounded border border-gray-100 bg-white py-0.5 shadow-lg"
            {...getFloatingProps()}
          >
            <DropdownProfile />
            {isOnboarded && (
              <MenuRow
                key="dropdown-settings"
                title="Settings"
                icon="settings"
                onClick={() => {
                  handleOpenSettings();
                  setIsOpen(false); // Close dropdown after action
                }}
              />
            )}
            <MenuRow
              key="dropdown-logout"
              title="Logout"
              icon="logout"
              onClick={() => {
                handleLogout();
                setIsOpen(false); // Close dropdown after action
              }}
            />
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
