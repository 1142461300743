import React, { useEffect, useState } from "react";
import { Tab, TabProps } from "./Tab";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { getCurrentPage } from "source/utils/helpers";
import { useAppDispatch } from "source/redux";
import { resetChatSession } from "source/redux/chat";
import { setMatrixHomePanel } from "source/redux/matrix";
import { MatrixHomePanelType } from "source/components/matrix/home/types";
import { MaterialSymbolsIcon } from "core";
import { useGlobalNavigator } from "source/hooks/useSetRouter";

export type TabType =
  | "Search"
  | "Chat"
  | "ChatDocs"
  | "Matrix"
  | "Alerts"
  | "Browse";

export const Tabs = () => {
  const enableDocumentsLists = useFeatureFlag("enableDocumentsLists");
  const chatPlus = useFeatureFlag("chatPlus");
  const hebbiaChat = useFeatureFlag("hebbiaChat");

  const { router } = useGetRouter();
  const currentPage = getCurrentPage(router.pathname);
  const { goToPage } = useGlobalNavigator();
  const dispatch = useAppDispatch();

  const [localCurrentTabIndex, setLocalCurrentTabIndex] = useState(0);

  const cleanupState = (location: string) => {
    if (
      (currentPage === "Chat" && location !== "/chat") ||
      (currentPage === "ChatDocs" && location !== "/chat-docs")
    ) {
      dispatch(resetChatSession({}));
    }

    if (currentPage === "Matrix") {
      dispatch(setMatrixHomePanel(MatrixHomePanelType.HOME));
    }
  };

  useEffect(() => {
    if (
      enableDocumentsLists &&
      !enableDocumentsLists &&
      router.pathname.includes("browse")
    ) {
      goToPage("/matrix");
    }
  }, [router, enableDocumentsLists]);

  const tabs: (Pick<TabProps, "icon" | "location" | "page"> & {
    enabled: boolean;
  })[] = [
    {
      page: "Matrix",
      icon: (
        <MaterialSymbolsIcon
          size="xl"
          variant="sharp"
          icon="auto_awesome_mosaic"
          filled={currentPage === "Matrix"}
          className="leading-none"
        />
      ),
      location: "/matrix",
      enabled: true,
    },
    {
      page: "Browse",
      icon: (
        <MaterialSymbolsIcon
          icon="folder_open"
          size="xl"
          filled={currentPage === "Browse"}
        />
      ),
      location: "/browse",
      enabled: enableDocumentsLists ?? false,
    },

    {
      page: "ChatDocs",
      icon: (
        <MaterialSymbolsIcon
          size="xl"
          variant="sharp"
          icon="forum"
          filled={currentPage === "ChatDocs"}
          className="leading-none"
        />
      ),
      location: "/chat-docs",
      enabled: chatPlus ?? false,
    },
    {
      page: "Chat",
      icon: (
        <MaterialSymbolsIcon
          size="xl"
          variant="sharp"
          icon="chat_bubble"
          filled={currentPage === "Chat"}
          className="leading-none"
        />
      ),
      location: "/chat",
      enabled: hebbiaChat ?? false,
    },
  ];

  const enabledTabs = tabs.filter((tab) => tab.enabled);

  useEffect(() => {
    const currentTabIndex = enabledTabs.findIndex(
      (tab) => tab.page === currentPage
    );
    setLocalCurrentTabIndex(currentTabIndex);
  }, [currentPage, enabledTabs]);

  return (
    <div className="flex w-full flex-none flex-col items-center">
      <div
        className="absolute left-0 h-9 w-1 self-start rounded-r-full bg-brand-500"
        style={{
          transform: `translateY(${localCurrentTabIndex * 44}px)`,
        }}
      ></div>
      <div className="flex w-full flex-col items-center gap-2">
        {enabledTabs.map((tab) => {
          return (
            <Tab
              {...tab}
              isSelected={currentPage === tab.page}
              onClick={() => cleanupState(tab.location)}
            />
          );
        })}
      </div>
    </div>
  );
};
